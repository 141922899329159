import { Swiper } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'

import 'swiper/scss'
import 'swiper/scss/pagination'

const PrimarySlider = ({ children, ...rest }) => {
  return (
    <>
      <Swiper
        slidesPerView={'auto'}
        pagination={{
          clickable: true
        }}
        modules={[Pagination, Autoplay]}
        {...rest}
      >
        {/*Wrap each Element inside SwiperSlide in loop*/}
        {children}
      </Swiper>
    </>
  )
}

export default PrimarySlider
